import './Header.css';
import React, {useState } from "react"
import { Container, Navbar, Nav, NavDropdown, Button, Stack } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap'
import trackingCall from "../utils/trackingCall"
import OutsideAlerter from '../utils/OutsideAlerter';
import logo from "../assets/UEFull_Whiteback.png"

const Header = () => {

  const [open, setOpen] = useState(false);

    return (

      // OutsideAlerter is a hook borrowed from stackoverflow to detect clicks outside the navbar menu so that
      // we can collpase it.   It's used in conjunction with the open, setOpen useState above.

     <OutsideAlerter setOpen={setOpen}>
     <Container fluid className="header-container" >

     <Navbar collapseOnSelect bg="white" expand="lg" expanded={open}  fixed="top" >
            <Navbar.Brand  href="https://www.utopiaexperiences.net"
            onClick={() => {
                trackingCall('Home')}}>
            <img className="logo"
                width='200px'
                height='auto'
                src={logo}
                alt="Utopia Experiences logo"
             />
            </Navbar.Brand>

          <Navbar.Toggle  aria-controls="basic-navbar-nav"
            onClick={() => {
                if (!open) {
                  setOpen(true)
                }
                else {
                  setOpen(false)
                }
            }}
          />

          <Navbar.Collapse  id="basic-navbar-nav"  className="justify-content-end">

            <Nav onKeyDown="collapse" className="mr-auto">

              <Nav.Link  eventKey="0" href="https://www.utopiaexperiences.net"
              onClick={() => {
                setOpen(false)
                trackingCall('UE Home Page Menu Selection')}}
                >Home</Nav.Link>

              <Nav.Link  eventKey="1" href="https://www.utopiaexperiences.net/solutions"
              onClick={() => {
                setOpen(false)
                trackingCall('UE Solutions Page Menu Selection')}}>Solutions</Nav.Link>

              <Nav.Link  eventKey="2" href="https://www.utopiaexperiences.net/about"
              onClick={() => {
                setOpen(false)
                trackingCall('About')}}>About</Nav.Link>

              <Nav.Link  eventKey="3" href="https://www.utopiaexperiences.net/contact"
              onClick={() => {
                setOpen(false)
                trackingCall('UE Contact Page Menu Selection')}}>Contact</Nav.Link>

              <NavDropdown id="collapsible-nav-dropdown"

                title={<span className="yellow-item">Hosts</span>}>
                <NavDropdown.Item  eventKey="4" className='nav-item dropdown-item' >
                <LinkContainer  to="/hosts" className='nav-item dropdown-item' >
                    <button type = "button"
                    onClick={() => {
                      setOpen(false)
                      trackingCall('Book a Host Menu Selection')}}
                      >Book a Host
                      </button>
                 </LinkContainer>
                </NavDropdown.Item>

                <NavDropdown.Item eventKey="5" className='nav-item dropdown-item' >
                <LinkContainer to="/hostauditions" className='nav-item dropdown-item'>
                    <button type = "button" className='nav-item dropdown-item'
                    onClick={() => {
                      setOpen(false)
                      trackingCall('Be a Host Menu Selection')}}
                      >Be a Host</button>
                </LinkContainer>
                </NavDropdown.Item>

                </NavDropdown>
              <Container fluid className="d-flex justify-content-center">

                  <Stack direction="horizontal" gap={2} >
                  <Button variant="primary" className="blue-button-header" href="https://www.utopiaexperiences.net/log-in"
                  onClick={() => {
                    setOpen(false)
                    trackingCall('UE Log In')}}>Log In</Button>
                  <Button variant="primary" className="blue-button-header" href="https://www.utopiaexperiences.net/contact"
                  onClick={() => {
                    setOpen(false)
                    trackingCall('Book a Demo')}}>Book Demo</Button>
                  </Stack>

              </Container>
            </Nav>

          </Navbar.Collapse>

      </Navbar>

      </Container>
      </OutsideAlerter>

    )
}

export default Header;