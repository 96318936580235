
import ReactGA from 'react-ga4';

// ReactGA calls for tracking.  Initialization is in index.js

function gaTrack(event) {

    // if (process.env.NODE_ENV === `production`) {
        ReactGA.event({
          category: "Navigation",
          action: event,
          label: event,
          nonInteraction: false ,
        })
    // }
  }

function trackingCall (source) {

    try {gaTrack(source, 'trackCustom');}
    catch (err) {alert(err)}

}

export default trackingCall