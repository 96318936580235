import './App.css';
import React, { Suspense }  from "react";
import {Switch, Route} from "react-router-dom";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SEO from "./components/seo"
import Header from "./components/Header";
import Footer from "./components/Footer";
const HostAuditions = React.lazy (() => import('./components/HostAuditions'));
const HostSectionContainer = React.lazy(() => import('./components/HostSectionContainer'));
const MoreAboutHostContainer = React.lazy(() => import('./components/MoreAboutHostContainer'));
const AboutProcessFAQ = React.lazy(() => import('./components/AboutProcessFAQ'));
const HostRatingDetailContainer = React.lazy(() => import('./components/HostRatingDetailContainer'));

function App() {

  return (
  <>
    <SEO />
    <ToastContainer
            position="bottom-left"
            autoClose={6000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
    <Header />
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/hostauditions" >
          <HostAuditions/>
        </Route>
        <Route exact path="/moreabouthost" >
          <MoreAboutHostContainer/>
        </Route>
        <Route exact path="/abouthostprocess">
          <AboutProcessFAQ/>
        </Route>
        <Route exact path="/hostratingdetail">
            <HostRatingDetailContainer/>
        </Route>
        <Route exact path= "/hosts">
          <HostSectionContainer/>
        </Route>
        <Route path= "/">
          <HostSectionContainer/>
        </Route>
      </Switch>
      </Suspense>
    <Footer />
  </>
  )
}

export default App;
