import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';

ReactGA.initialize([
  {
    trackingId: "G-DPBM5LQNS1",
    debug: false,
    titleCase: false,
    gaOptions: {
      siteSpeedSampleRate: 100,
    }
  },
]);

//record pagevew with Google Analytics
ReactGA.send({ hitType: "pageview", page: "/hostspage" });


ReactDOM.render(
  <React.StrictMode>
    <Router >
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
