import './Footer.css';
import React  from "react"
// import { Container, Row, Col,} from 'react-bootstrap';
import logo from "../assets/UEFull_Whiteback.png"

// Note:  Content and layout is largely copied and pasted from ue-main-website.  File: about.html.
//        Minor changes to remove unneeded classes and other not noted.  Also fixed some bad practices
//        implemented via WebFlow in the creation of the ue-main-website.  Straight HTML in React.

// BTW:  Copy and pasting code is not good practice, but this is a startup and we have a lot of work
//       to do and we're short funds right now!


const Footer = () => {
    return (
    <footer id="footer">
    <div className="w-container">
      <div className="footer-flex-container">
        <a href="https://www.utopiaexperiences.net" className="footer-logo-link">
        <img className="footer-logo-link footer-image" src={logo} height="" width="200"  alt="Utopia Experiences colored logo"/>
        </a>
        <div>
          <h2 className="footer-heading">Links</h2>
          <ul className="w-list-unstyled">
            <li>
              <a href="https://www.utopiaexperiences.net" className="footer-link">Home</a>
            </li>
            <li>
              <a href="https://www.utopiaexperiences.net/solutions" className="footer-link">Solutions</a>
            </li>
            <li>
              <a href="https://www.utopiaexperiences.net/about" aria-current="page" className="footer-link w--current">About</a>
            </li>
            <li>
              <a href="https://www.utopiaexperiences.net/contact" className="footer-link">Contact</a>
              <a href="https://www.iubenda.com/privacy-policy/25699235" className="footer-link">Privacy Policy</a>
              <a href="https://www.iubenda.com/privacy-policy/25699235/cookie-policy" className="footer-link">Cookie Policy</a>
            </li>
          </ul>
        </div>
        <div>
          <h2 className="footer-heading">Get Social</h2>
          <ul className="w-list-unstyled">
            <li>
              <a href="https://www.facebook.com/gaming/utopiaexperiences" className="footer-link">Facebook</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/utopia-experiences-llc" className="footer-link">LinkedIn</a>
            </li>

            <li>
            <a href="https://www.instagram.com/utopia_experiences/" className="footer-link">Instagram</a>
            </li>
            </ul>
        </div>
        <div className="div-block-23">
          <h2 className="footer-heading">Get In Touch</h2>
          <ul className="w-list-unstyled">
            <li>
              <a href="mailto:info@utopiaexperiences.net?subject=From%20Utopia%20Experiences.net" className="footer-link">info@utopiaexperiences.net</a>
            </li>
            <li>
              <a href="tel:863-968-7529" className="footer-link">☎︎ (863)YOU PLAY <strong>/</strong> (863) 968-7529</a>
            </li>
          </ul>
        </div>
      </div>
      <div>Copyright © 2021 Utopia Experiences, LLC. All rights reserved.</div>
    </div>
  </footer>

    )
}

export default Footer