
import React, { useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref.  Lifted from Stackoverflow
 */
function useOutsideAlerter(ref,setOpen) {


    useEffect(() => {
        /**
         * Set a useState if clicked outsite Navbar dropdown
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false)

            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setOpen]);
}

/**
 * Component that detects if you click outside of it
 */
export default function OutsideAlerter(props) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.setOpen);

    return <div ref={wrapperRef}>{props.children}</div>;
}