import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

// this code's borrowed from a Gatsby SEO component.  Refactored a bit.

const SEO = () => {

  const seo = {
    title: `Utopia Experiences Hosts`,
    description: `Meet our sponsored game show hosts.  Book one for your next game!`,
    image: "UElogo_whiteback.png",
    url: `https://hostapp.utopiaexperiences.cloud/`,
  }

  return (
    <Helmet title={seo.title} >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}

      {/* Canonical tag for search engines */}

      <link rel="canonical" href="https://hostapp.utopiaexperiences.cloud" />
      <meta name="" content="index,follow,max-image-preview:large" />

      <meta name="keywords" content="Utopia Experiences live virtual senior games spintopia hosts booking congregate community game platform engagement family satisfaction" />

    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

